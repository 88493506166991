// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";

// mobx
import { observer } from "mobx-react";

import Footer from "components/Footer";

// semantic-ui
import {
  Header,
  Segment,
  Container,  
  Image,
  Card  
} from "semantic-ui-react";

export const HomePage = observer(() => (
  <>      
    <Segment      
      textAlign="left"
      style={{
        // color: "whitesmoke",
        backgroundColor: "#f8f7f6",
        padding: "40px 0px",
        margin: "0px !important",
      }}
    >
      <Header
        style={{
          textAlign: "left",
          // color: "whitesmoke",
          backgroundColor: "#f8f7f6",
          padding: "40px 0px",
          marginLeft: "100px",
          marginRight: "100px",
          marginTop: "0px",
          marginBottom: "0px",
        }}
      >
        <h1 className="custom-border">Ikano API Management</h1>                
        <p>
          Empower developers to build apps against our APIs quickly and
          securely.
        </p>
      </Header>
    </Segment>

    <Container>
      <Header
        as="h1"
        style={{
          padding: "35px 0px",
          marginLeft: "105px",
          marginRight: "105px",
          marginTop: "0px",
          marginBottom: "0px",
          borderTop: "1px solid lightgrey",
        }}
      >
        Portal Features
      </Header>

      <Card.Group centered>
        <Card raised color="red">
          <Card.Content>
            <Image
              floated="right"
              size="mini"
              src="/custom-content/documentation-icon.jpg"
            />  
            <Card.Header>Browse API Documentation</Card.Header>
            <Card.Description>
              Comprehensive information about endpoints, authentication methods,
              request/response formats, and usage guidelines, all in one place.
            </Card.Description>
          </Card.Content>
        </Card>

        <Card raised color="green">
          <Card.Content>
            <Image
              floated="right"
              size="mini"
              src="/custom-content/SDK_icon.png"
            />
            <Card.Header>Generate SDK</Card.Header>
            <Card.Description>
              Get your REST API endpoint's SDK with ease.
            </Card.Description>
          </Card.Content>
        </Card>

        <Card raised color="olive">
          <Card.Content>
            <Image
              floated="right"
              size="mini"
              src="/custom-content/async-api-logo.png"
            />
            <Card.Header>AsyncAPI Specification</Card.Header>
            <Card.Description>
              Access to information about asynchronous API endpoints, including
              how to use them to handle asynchronous tasks and responses in
              applications.
            </Card.Description>
          </Card.Content>
        </Card>

        <Card raised color="teal">
          <Card.Content>
            <Image
              floated="right"
              size="mini"
              src="/custom-content/openapi-logo-preview.png"
            />
            <Card.Header>Export REST API</Card.Header>
            <Card.Description>
              Easily export REST APIs in both OpenAPI 3 and Swagger 2
              specification.
            </Card.Description>
          </Card.Content>
        </Card>

        <Card raised color="violet">
          <Card.Content>
            <Image
              floated="right"
              size="mini"
              src="/custom-content/endpoint-security-new.jpg"
            />
            <Card.Header>Endpoint Security</Card.Header>
            <Card.Description>
              Offers comprehensive security details for all API endpoints.
            </Card.Description>
          </Card.Content>
        </Card>

        <Card raised color="purple">
          <Card.Content>
            <Image
              floated="right"
              size="mini"
              src="/custom-content/workflow-icon-new.png"
            />
            <Card.Header>Workflows</Card.Header>
            <Card.Description>
              Ensure that only authorized users can access and use the API
              Portal.
            </Card.Description>
          </Card.Content>
        </Card>
      </Card.Group>
    </Container>
    <Footer />   
  </>
));

export default HomePage;
