import React, { Component } from "react";
import { BrowserRouter as Router, Redirect } from "react-router-dom";

import { ApiManagement, SideNav } from "./";
import { AdminRoute } from "./../../";
import PageWithSidebar from "components/PageWithSidebar";

import RegisteredAccounts from "pages/Admin/Accounts/RegisteredAccounts";
import AdminAccounts from "pages/Admin/Accounts/AdminAccounts";
import OnlyRestAPIAccounts from "pages/Admin/Accounts/OnlyRestAPIAccounts";
import OnlyWebsocketAccounts from "pages/Admin/Accounts/OnlyWebsocketAccounts";
import EventBasedGroupAccounts from "pages/Admin/Accounts/EventBasedGroupAccounts";
import PublicAPIAccounts from "pages/Admin/Accounts/PublicAPIAccounts";
import SpecifiRestAPIGroupAccounts from "pages/Admin/Accounts/SpecificRestAPIGroupAccounts";
import SpecifiWebsocketAPIGroupAccounts from "pages/Admin/Accounts/SpecificWebsocketAPIGroupAccounts";
import DeveloperToolsAccounts from "./Accounts/DeveloperToolsAccounts";
// import PendingRequests from 'pages/Admin/Accounts/PendingRequests'

function RedirectToApiManagement() {
  return <Redirect to="/admin/apis" />;
}

export class Admin extends Component {
  render() {
    return (
      <Router>
        <PageWithSidebar sidebarContent={<SideNav />}>
          <>
            <AdminRoute
              exact
              from="/admin"
              component={RedirectToApiManagement}
            />
            <AdminRoute path="/admin/apis" component={ApiManagement} />
            <AdminRoute
              exact
              path="/admin/accounts"
              component={RegisteredAccounts}
            />
            <AdminRoute
              exact
              path="/admin/accounts/admins"
              component={AdminAccounts}
            />
            <AdminRoute
              exact
              path="/admin/accounts/rest-apis-users"
              component={OnlyRestAPIAccounts}
            />
            <AdminRoute
              exact
              path="/admin/accounts/websocket-apis-users"
              component={OnlyWebsocketAccounts}
            />
            <AdminRoute
              exact
              path="/admin/accounts/event-based-apis-users"
              component={EventBasedGroupAccounts}
            />
            <AdminRoute
              exact
              path="/admin/accounts/public-apis-users"
              component={PublicAPIAccounts}
            />
            <AdminRoute
              exact
              path="/admin/accounts/specific-rest-apis-users"
              component={SpecifiRestAPIGroupAccounts}
            />
            <AdminRoute
              exact
              path="/admin/accounts/specific-websocket-apis-users"
              component={SpecifiWebsocketAPIGroupAccounts}
            />
            <AdminRoute
              exact
              path="/admin/accounts/developer-tools-users"
              component={DeveloperToolsAccounts}
            />
            {/* <AdminRoute exact path='/admin/accounts/invites' component={PendingInvites} /> */}
            {/* <AdminRoute exact path='/admin/accounts/requests' component={PendingRequests} /> */}
          </>
        </PageWithSidebar>
      </Router>
    );
  }
}
