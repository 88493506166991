import React from 'react'
import { Container, Table, Header, Segment, Icon } from 'semantic-ui-react';
import { isAdmin } from 'services/self';
import { GREEN_TICK, RED_CROSS } from './Columns';

function EndpointSecurityTable({columns,api}) {
 
    return (
        <Container fluid style={{ padding: '2em' }}>
        <Header as='h1'>{api.name}</Header>
        { isAdmin() &&
        <Segment>
          <Header as='h4'>Private WAF: {api.hasPrivateWAF ? GREEN_TICK : RED_CROSS}</Header>
          <Header as='h4'>Global WAF: {api.hasGlobalWAF ? GREEN_TICK : RED_CROSS}</Header>
        </Segment>
        }
        {api.curityScopes.length ?   
        <Table>
          <Table.Header>
            <Table.Row>
              {columns.map((column, index) => (
                <Table.HeaderCell key={index} textAlign='center'>
                  {column.title}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {api.curityScopes.map(scope=>(
              <Table.Row key={scope.end_point}>
                {columns.map(column=>(
                  <Table.Cell key={column.id} textAlign='center'>
                    <div style={{textAlign:'center'}}>{column.render(scope)}</div>
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table> :
        <Header as='h2' icon textAlign='center' style={{ padding: '40px 0px', width: '600px' }}>
          <Icon name='red warning sign' circular />
          <Header.Content>No security information found</Header.Content>
        </Header>
        }
        </Container>
    )
}

export default EndpointSecurityTable
