import React, { useEffect, useState } from "react";
import { Button, Container, Form, Header, Segment } from "semantic-ui-react";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import _ from "lodash";
import { useLocation } from "react-router-dom";

function WebsocketTestClient() {
  const { state } = useLocation();
  const [url, setUrl] = useState(state ? state.webSocketUrl : "");
  const [authToken, setAuthToken] = useState("");
  const [socket, setSocket] = useState(null);
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState("");
  const [response, setResponse] = useState("");
  const [error, setError] = useState(null);
  const [urlError, setUrlError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (socket) {
      // Listen for connection events
      socket.addEventListener("open", () => {
        setLoading(false);
        setOpen(true);
        setUrlError(null);
      });

      socket.addEventListener("close", () => {
        setOpen(false);
        setSocket(null);
      });

      // Listen for messages from the server
      socket.addEventListener("message", (data) => {
        setResponse(JSON.stringify(JSON.parse(data.data), null, 1));
      });

      socket.addEventListener("error", () => {
        setLoading(false);
        setUrlError("Connection failed");
      });
    }

    // Clean up on component unmount
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [socket]);

  const handleConnection = (webSocket) => {
    if (webSocket) {
      if (webSocket.readyState === WebSocket.OPEN) {
        webSocket.close();
      }
    } else {
      try {
        //Setting the authToken
        const webSocketUrl = new URL(url);
        const searchParams = webSocketUrl.searchParams;
        searchParams.set("authtoken", authToken);
        webSocketUrl.search = searchParams.toString();
        //Establishing WebSocket connection
        webSocket = new WebSocket(webSocketUrl.toString());
        setLoading(true);
        setSocket(webSocket);
      } catch (error) {
        setUrlError(error.message);
      }
    }
  };

  const validateJSON = (request) => {
    try {
      JSON.parse(request);
      setRequest(request);
      setError(null);
    } catch (error) {
      setRequest(request);
      setError(_.isEmpty(request) ? null : error.message);
    }
  };

  const sendRequest = (request) => {
    socket.send(request);
  };

  return (
    <Container style={{ padding: "2em", width: "100%", background: "#292a34" }}>
      <Segment>
        <Form>
          <Form.Input
            label="WebSocket URL"
            placeholder="Enter the WebSocket URL"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
          <Form.Input
            label="Auth token"
            placeholder="Enter the Auth token"
            value={authToken}
            onChange={(e) => setAuthToken(e.target.value)}
          />
          <Form.Button
            color="blue"
            disabled={_.isEmpty(url) || _.isEmpty(authToken)}
            onClick={() => handleConnection(socket)}
            loading={loading}
          >
            {open ? "Close" : "Open"}
          </Form.Button>
          <Form.Field>
            Status:{" "}
            <b style={{ color: open ? "green" : "red" }}>
              {urlError ? urlError : open ? "OPEN" : "CLOSED"}
            </b>
          </Form.Field>
        </Form>
      </Segment>
      <Segment.Group horizontal>
        <Segment style={{ width: "50%" }}>
          <Header
            style={{ float: "left", marginTop: "5px", paddingRight: "1rem" }}
          >
            Request payload
          </Header>
          <div>
            <Button
              color="teal"
              disabled={_.isEmpty(request) || error}
              onClick={() =>
                setRequest(JSON.stringify(JSON.parse(request), null, 1))
              }
            >
              Prettify
            </Button>
            <Button
              color="teal"
              disabled={_.isEmpty(request)}
              onClick={() => {
                setRequest("");
                setResponse("");
                setError(null);
              }}
            >
              Clear
            </Button>
            <Button
              color="orange"
              disabled={_.isEmpty(request) || !open || error}
              onClick={() => sendRequest(request)}
            >
              Send
            </Button>
          </div>
          <CodeMirror
            style={{ marginTop: "10px" }}
            theme={"dark"}
            width="100%"
            maxWidth="100%"
            height="500px"
            value={request}
            extensions={[json()]}
            onChange={(value, viewUpdate) => validateJSON(value)}
          />
        </Segment>
        <Segment style={{ width: "50%" }}>
          <Header
            style={{ float: "left", marginTop: "5px", paddingRight: "1rem" }}
          >
            Response
          </Header>
          <Button
            color="teal"
            disabled={_.isEmpty(response) || error}
            onClick={() => {
              setResponse("");
              setError(null);
            }}
          >
            Clear
          </Button>
          <CodeMirror
            style={{ marginTop: "10px" }}
            theme={"dark"}
            width="100%"
            maxWidth="100%"
            height="500px"
            value={error ? error : response}
            readOnly
            extensions={[json()]}
          />
        </Segment>
      </Segment.Group>
    </Container>
  );
}

export default WebsocketTestClient;
