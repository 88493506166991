// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import * as queryString from "query-string";


// semantic-ui
import "semantic-ui-css/semantic.css";

// pages
import Home from "pages/Home";
// import GettingStarted from 'pages/GettingStarted'

import Dashboard from "pages/Dashboard";
import Apis from "pages/Apis";

import { Admin } from "pages/Admin";
import WebSocketAPIs from "pages/WebSocketAPIs";
import EventBasedApis from "pages/EventBasedAPIs";
import EndpointSecurity from "./pages/EndpointSecurity";

// components
import AlertPopup from "components/AlertPopup";
import GlobalModal from "components/Modal";
import NavBar from "components/NavBar";
import Feedback from "./components/Feedback";

import {
  isAdmin,
  isAuthenticated,
  isRegistered,
  isRestAPIUser,
  isWsAPIUser,
  isBasedEventAPIUser,
  init,
  login,
  logout,
  isDeveloperToolsUser,
} from "services/self";
import "./index.css";
import Welcome from "components/Welcome";
import DeveloperTools from "pages/DeveloperTools/DeveloperTools";

// TODO: Feedback should be enabled if
// the following is true && the current
// user is not an administrator
const feedbackEnabled = window.config.feedbackEnabled;

export const RegisteredRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isRegistered() ? <Component {...props} /> : <Redirect to="/" />
    }
  />
);

export const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAdmin() ? <Component {...props} /> : <Redirect to="/" />
    }
  />
);

export const RestRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAdmin() || isRestAPIUser() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

export const WsRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAdmin() || isWsAPIUser() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

export const BasedEventRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAdmin() || isBasedEventAPIUser() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

export const EndpointSecurityRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAdmin() || isRestAPIUser() || isWsAPIUser() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

export const DeveloperToolsRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAdmin() || isDeveloperToolsUser() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

// To shut up a dev warning
const HomeWrap = (props) => <Home {...props} />;
// const GettingStartedWrap = props => <GettingStarted {...props} />
const WebsocketApisWrap = (props) => <WebSocketAPIs {...props} />;
const EventBasedApisWrap = (props) => <EventBasedApis {...props} />;
const DashboardWrap = (props) => <Dashboard {...props} />;
const EndpointSecurityWrap = (props) => <EndpointSecurity {...props} />;
const DeveloperToolsWrap = (props) => <DeveloperTools {...props} />;

class App extends React.Component {
  constructor() {
    super();
    init();

    // We are using an S3 redirect rule to prefix the url path with #!
    // This then converts it back to a URL path for React routing
    if (window.location.hash && window.location.hash[1] === "!") {
      const hashRoute = window.location.hash.substring(2);
      window.history.pushState({}, "home page", hashRoute);
    }
  }

  render() {
    return (
      <BrowserRouter>
        <NavBar />
        <GlobalModal />
        <Switch>
          <Route
            exact
            path="/"
            component={() => {
              return isAuthenticated() ? <Welcome /> : <HomeWrap />;
            }}
          />
          <Route
            exact
            path="/index.html"
            component={() => {
              const { action } = queryString.parse(window.location.search);
              if (action === "login") {
                login();
              } else if (action === "logout") {
                logout();
              }
              return <Redirect to="/" />;
            }}
          />

          <WsRoute exact path="/websocket-apis" component={WebsocketApisWrap} />
          <WsRoute
            path="/websocket-apis/:directory+"
            component={WebsocketApisWrap}
          />
          <BasedEventRoute
            exact
            path="/event-based-apis"
            component={EventBasedApisWrap}
          />
          <BasedEventRoute
            path="/event-based-apis/:directory+"
            component={EventBasedApisWrap}
          />
          <EndpointSecurityRoute
            path="/endpoint-security"
            component={EndpointSecurityWrap}
          />
          <RegisteredRoute path="/dashboard" component={DashboardWrap} />
          <RegisteredRoute
            path="/developer-tools"
            component={DeveloperToolsWrap}
          />
          <AdminRoute path="/admin" component={Admin} />
          <RestRoute exact path="/apis" component={Apis} />
          <RestRoute exact path="/apis/:apiId" component={Apis} />
          <RestRoute path="/apis/:apiId/:stage" component={Apis} />
          <Route
            path="/login"
            render={() => {
              login();
              return <Redirect to="/" />;
            }}
          />
          <Route
            path="/logout"
            render={() => {
              logout();
              return <Redirect to="/" />;
            }}
          />
          <Route
            component={() =>
              isAuthenticated() ? <h2>Page not found</h2> : <Redirect to="/" />
            }
          />
        </Switch>
        {feedbackEnabled && <Feedback />}
        <AlertPopup />
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));
