import React, { useEffect,useState } from 'react'
import PageWithSidebar from 'components/PageWithSidebar'
import EndpointSecurityMenu from '../components/EndpointSecurityMenu'
import { store } from "services/state";
import { updateEndpointACLApisList } from '../services/api-catalog'
import EndpointSecurityTable from 'components/EndpointSecurityTable';
import { RestApiColumns,WebsocketApiColumns } from 'components/Columns';
import Sidebar from "components/Sidebar/Sidebar";
import _ from 'lodash'

import {
  isRestAPIUser,
  isWsAPIUser
} from "services/self"
import { Container, Header, Image, List, Grid, GridColumn, Menu, Search } from 'semantic-ui-react';

import SidebarHeader from 'components/Sidebar/SidebarHeader';

export const REST_API = "Rest APIs"
export const WEBSOCKET_API = "Websocket APIs"

function EndpointSecurity() {
    const [endpointACLList, setEndpointACLList] = useState(store.endpointACLApiList)
    const [loaded, setLoaded] = useState(false)
    const [columns, setColumns] = useState()
    const [api, setApi] = useState(null)
    const [searchTerm , setSearchTerm] = useState("")
    const [filteredResults , setFilteredResults] = useState("")        
    useEffect(() => {
        async function fetchData() {
          await updateEndpointACLApisList(true);
          if (store.endpointACLApiList.loaded) {
            setEndpointACLList(store.endpointACLApiList)
            if (isRestAPIUser()){
              setColumns(RestApiColumns)
            }
            else if (isWsAPIUser()){
              setColumns(WebsocketApiColumns)
            }
            setLoaded(true)
          }
        }
        fetchData();
    }, []);
    
    const displayTable =(api,apiType)=>{         
      setApi(api)  
      // handle both search and manual click  
      if(apiType === "restApis") apiType = REST_API 
      else if (apiType === "websocketApis") apiType = WEBSOCKET_API 
      if(apiType === REST_API) setColumns(RestApiColumns)
      else if (apiType === WEBSOCKET_API) setColumns(WebsocketApiColumns)
    }

const handleSearchChange = (e, { value }) => {
  setSearchTerm(value);
  const filteredData = Object.entries(endpointACLList).flatMap(([categoryName, categoryItems]) => {  
    if(categoryName === 'restApis' || categoryName === 'websocketApis')
    {
      return categoryItems.filter(item => 
        item.name.toLowerCase().includes(searchTerm.toLowerCase()))
      .map(item => ({...item, categoryName})) 
    }
    return [];
    });       
    setFilteredResults(filteredData);  
}

const handleSelect =(e, {result}) => {
  try {    
      if(result){
        if(displayTable)
        displayTable(result, result.categoryName)
        else
        console.warn("Result is undefined or null")
  } 
  } catch (error) {
    console.error("Error in handleSelect", error)
  }   
}

const resultRenderer = ({id, name,categoryName}) => {
  return(
  <Menu.Menu    
    key={id}
    onClick= {(e) => handleSelect(e, {result: {id, name, categoryName}}
      )}
    style={{ display: 'inline-block', width: '100%', height: '100%' }}
  >
    <span>{name}</span>
  </Menu.Menu>)
  }

const sidebar = 
    <Sidebar> 
        <SidebarHeader 
             className='item' style={{ fontWeight: '400', fontSize: '1em', cursor: 'pointer' }}
             onClick={()=>setApi(null)}
            >
                Endpoint Security
        </SidebarHeader>
        <Grid>
          <GridColumn width={8}>
            <Search
              placeholder='Search API'                                
              onSearchChange={_.debounce(handleSearchChange, 500, {
                leading: true,
              })}      
              onResultSelect={(e, {result}) =>handleSelect(e, {result})}            
              resultRenderer={resultRenderer}            
              results={filteredResults}
              value={searchTerm}
            />
          </GridColumn>
        </Grid> 
        {loaded && <EndpointSecurityMenu apiList={endpointACLList} showTable={displayTable} selected={api && api.id} searchResult={searchTerm} />}           
    </Sidebar>

    return (
        <PageWithSidebar
        
            sidebarContent={sidebar}
            SidebarPusherProps={{ className: 'swagger-section' }}
        >
          {api ? <EndpointSecurityTable columns={columns} api={api} /> :
          
            <Container fluid style={{ padding: '2em' }}>
              <Header as='h2'>Overview</Header>
              <p>Securing API access is crucial for protecting data and ensuring reliable interactions with our services. API Gateway system is engineered to provide robust authentication, safeguarding Client transactions while maintaining seamless connectivity. Here's a brief overview of our authentication process: </p>
              <List bulleted relaxed>
                <List.Item>
                  <List.Header as='h4'>Split-Token Approach: </List.Header>
                  <List.Description>We leverage a split-token method, where the Client send a JWT signature as a bearer token. This strategy enable security by separating token responsibilities, minimizing exposure and risks.</List.Description>
                </List.Item>
                
                <List.Item>
                <List.Header as='h4'>Efficient Token Management: </List.Header>
                <List.Description>Upon Client request, our API Gateway collaborates with Curity to assemble the complete JWT token. This token is then securely relayed to our backend systems, ensuring that your access is authenticated promptly and securely.</List.Description>
                </List.Item>

                <List.Item>
                <List.Header as='h4'>Accessible Token Retrieval: </List.Header>
                <List.Description>Acquire your JWT token easily through our dedicated token example URI: <i>https://dev-org-curity.sec.bank.ikano/oauth/v2/oauth-token</i>. This endpoint is your reliable source for secure authentication tokens.</List.Description>
                </List.Item>

                <List.Item>
                <List.Header as='h4'>API Types: </List.Header>
                <List.Description>To accommodate various application needs, we offer both REST and WebSocket APIs. To enable flexible integration, aligning with different technical requirements and preferences.</List.Description>
                </List.Item>

                <List.Item>
                  <List.Header as='h4'>Token Placement Guidelines: </List.Header>
                  <List bulleted>
                    <List.Item>Authorization header for REST API</List.Item>
                    <List.Item>Query params for websocket API (auth-token and action ) </List.Item>
                  </List>
                </List.Item>
              </List>
              <Header as='h3'>Authentication Flow</Header>
              <p>Below you can find a diagram of authentication activity flow</p>
              <br></br>
              <Image src='/custom-content/endpoint-security-flow.png' centered></Image>
              
              <Header as='h4'>Authentication flow steps:</Header>
              <List ordered>
                <List.Item>Mobile/Web app (consumers) will get JWT signature token from token service using proper client and scopes details.</List.Item>
                <List.Item>Consumers will receive a token and make API call</List.Item>
                <List.Item>Send received token with request to API Gateway(API GW) which will be fronted by WAF firewall for protecting from security threats.</List.Item>
                <List.Item>AP GW will perform AuthN/AuthZ with Curity and checks throttling policies before processing request.</List.Item>
                <List.Item>API GW sends transformed request with complete JWT token to backend modules via network load balancer.</List.Item>
                <List.Item>Microservices will push response back to API GW using connection URL for asynchronous calls</List.Item>
              </List>
            </Container>
          }
        </PageWithSidebar>
    )
}

export default EndpointSecurity
