import React from "react";
import { Button, Message, Modal } from "semantic-ui-react";

export const DeleteAccountModal = React.memo(
  ({ accounts, userGroup, onConfirm, open, onClose, isAdmin, isAllAccounts }) =>
    accounts && (
      <Modal size="small" open={open} onClose={onClose}>
        <Modal.Header>Delete accounts</Modal.Header>
        <Modal.Content>
          {isAdmin && (
            <Message negative>
              <Message.Header>Danger! This is an admin account.</Message.Header>
              <p>
                <strong>
                  Deleting an admin account could cause temporary loss of access
                  and temporary inability to configure the developer portal.
                </strong>
              </p>
            </Message>
          )}
          {isAllAccounts ? (
            <Message negative>
              <Message.Header>
                Danger! You've deleting all the accounts
              </Message.Header>
              <p>
                <strong>
                  Are you sure you want to delete all the accounts?
                </strong>
              </p>
            </Message>
          ) : (
            <p>
              Are you sure you want to delete the account(s){" "}
              {accounts.map((account, index) => (
                <strong key={index}>
                  {(index ? ", " : "") + account.EmailAddress}
                </strong>
              ))}{" "}
              and remove users from <strong>{userGroup}</strong>?
            </p>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <Button negative onClick={onConfirm}>
            Delete
          </Button>
        </Modal.Actions>
      </Modal>
    )
);

export const DeleteSuccessMessage = React.memo(
  ({ accounts, userGroup, dismiss, isAllAccounts }) => (
    <Message onDismiss={dismiss} positive>
      {isAllAccounts ? (
        <Message.Content>
          Successfully deleted all the accounts from{" "}
          <strong>{userGroup}</strong>.
        </Message.Content>
      ) : (
        <Message.Content>
          Successfully deleted account(s){" "}
          {accounts.map((account, index) => (
            <strong key={index}>
              {(index ? ", " : "") + account.EmailAddress}
            </strong>
          ))}{" "}
          from <strong>{userGroup}</strong>.
        </Message.Content>
      )}
    </Message>
  )
);

export const DeleteFailureMessage = React.memo(
  ({ accounts, userGroup, errorMessage, dismiss, isAllAccounts }) => (
    <Message onDismiss={dismiss} negative>
      <Message.Content>
        {isAllAccounts ? (
          <p>Failed to delete all the accounts.</p>
        ) : (
          <p>
            Failed to delete account(s){" "}
            {accounts.map((account, index) => (
              <strong key={index}>
                {(index ? ", " : "") + account.EmailAddress}
              </strong>
            ))}{" "}
            from <strong>{userGroup}</strong>.
          </p>
        )}
        {errorMessage && <p>Error message: {errorMessage}</p>}
      </Message.Content>
    </Message>
  )
);
