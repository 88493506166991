// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";

import Footer from "components/Footer";

// semantic-ui
import {
  Header,
  Container,  
  Card,
  Segment 
} from "semantic-ui-react";


function Welcome () {
  return (
    <>      
  <Segment
    vertical
    textAlign="left"
    style={{
      // color: "whitesmoke",
      //backgroundColor: "#f8f7f6",
      padding: "20px 10px",
      margin: "0px !important",
    }}
  >
  <article className='styles-article'>
      <img className='styles-image' src="/custom-content/Welcome_icon4.jpg" alt="background" />
      <h1 className='styles-header'>Welcome to the developer portal of Ikano Bank</h1>
  </article>  
    
    </Segment> 
    <Container>
      <Header
        as="h1"
        style={{
          padding: "35px 0px",
          marginLeft: "105px",
          marginRight: "105px",
          marginTop: "0px",
          marginBottom: "0px",
          borderTop: "1px solid lightgrey",          
        }}
      >
        Explore our APIs
      </Header>

      <Card.Group centered>
        <Card raised link color="red" href="https://apiportal.eem.dev.bank.ikano/apis">
          <Card.Content>
           <Card.Header centered>Rest APIs</Card.Header>
            <Card.Description>
            <p style={{textAlign: "center"}}>Explore Swagger documentation, showcasing a variety of well documented APIs, ready to smoothly integrate into your projects.</p>
            </Card.Description>
          </Card.Content>
        </Card>

        <Card raised link color="yellow" href="https://apiportal.eem.dev.bank.ikano/websocket-apis">
          <Card.Content>
            <Card.Header centered>WebSocket APIs</Card.Header>
            <Card.Description>
            <p style={{textAlign: "center"}}>Check out documentation for asynchronous communication in UI services with RESTful endpoints.</p> 
            </Card.Description>
          </Card.Content>
        </Card>

        <Card raised link color="orange" href="https://apiportal.eem.dev.bank.ikano/event-based-apis">
          <Card.Content>
            <Card.Header centered>EventBased APIs</Card.Header>
            <Card.Description>
            <p style={{textAlign: "center"}}>Discover user-friendly docs on Kafka-triggered event APIs to enhance your understanding of dynamic communication.</p>
            </Card.Description>
          </Card.Content>
        </Card>                  
      </Card.Group></Container>     
    <Footer />
    </>
  )
};

export default Welcome;