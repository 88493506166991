import React, { useCallback, useEffect, useState } from "react";
import MenuLink from "components/MenuLink";
import { fetchFileNames } from "services/editor";
import { Loader } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";

function Editor() {
  const apiTypes = ["REST", "WebSocket", "Event Based"];
  const [active, setActive] = useState({ apiType: "", fileName: "" });
  const [loading, setLoading] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const history = useHistory();
  const { state } = useLocation();

  const toggleExpand = (apiType) => {
    active.apiType === apiType
      ? setActive({ apiType: "" })
      : getFileNames(apiType);
  };

  const getLowerCase = (string) => {
    return string.replace(" ", "").toLowerCase();
  };

  const getFileNames = useCallback(async (apiType) => {
    setActive({ apiType });
    setLoading(true);
    const response = await fetchFileNames(getLowerCase(apiType));
    if (response.error) {
      setFileNames([]);
    } else {
      setFileNames(response.data.fileNames);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (state?.apiType) {
      getFileNames(state.apiType);
    }
    setActive({ apiType: state?.apiType, fileName: state?.fileName });
  }, [getFileNames, state]);

  const redirectToContent = (apiType, fileName) => {
    setActive({ apiType, fileName });
    history.push({
      pathname: `/developer-tools/api-specification-editor/${getLowerCase(
        apiType
      )}/${fileName}`,
      state: { apiType, fileName },
    });
  };

  return (
    <>
      <MenuLink
        style={{
          fontWeight: "400",
          paddingLeft: "2em",
          alignItems: "center",
        }}
      >
        Saved files
      </MenuLink>
      {apiTypes.map((apiType, index) => (
        <React.Fragment key={index}>
          <MenuLink
            active={active.apiType === apiType}
            onClick={() => toggleExpand(apiType)}
            style={{
              fontWeight: "400",
              paddingLeft: "2em",
              alignItems: "center",
              display: "flex",
            }}
          >
            {apiType}
            <span
              className="material-symbols-outlined"
              style={{
                transform: `rotate(${active.apiType === apiType ? 180 : 0}deg)`,
                transition: "all 0.25s",
              }}
            >
              expand_more
            </span>
          </MenuLink>
          {active.apiType === apiType &&
            (fileNames.length > 0 ? (
              fileNames.map((fileName, index) => (
                <MenuLink
                  key={index}
                  active={active.fileName === fileName}
                  onClick={() => redirectToContent(apiType, fileName)}
                  style={{
                    paddingLeft: "3em",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  {fileName}
                </MenuLink>
              ))
            ) : (
              <MenuLink
                style={{
                  paddingLeft: "3em",
                }}
              >
                {loading ? (
                  <Loader size="tiny" inline active />
                ) : (
                  "No results found"
                )}
              </MenuLink>
            ))}
        </React.Fragment>
      ))}
    </>
  );
}

export default Editor;
