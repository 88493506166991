import { Image } from "semantic-ui-react";

export const GREEN_TICK = (
  <Image
    width="20"
    height="20"
    centered
    src="/custom-content/green-tick.png"
  ></Image>
);

export const RED_CROSS = (
  <Image
    width="20"
    height="20"
    centered
    src="/custom-content/red-cross.png"
  ></Image>
);

const ENDPOINT = {
  id: "Endpoint",
  title: "Endpoint",
  render: (scope) => (scope.api_endpoint ? scope.api_endpoint : RED_CROSS),
};

const ROUTE = {
  id: "route",
  title: "Route",
  render: (scope) => (scope.api_endpoint ? scope.api_endpoint : RED_CROSS),
};

const SCOPES = {
  id: "Scopes",
  title: "Security Scopes",
  render: (scope) => (scope.scope ? scope.scope : RED_CROSS),
};

const AUTH_TYPE = {
  id: "authType",
  title: "Authorization Type",
  render: (scope) => (scope.authType ? scope.authType : RED_CROSS),
};

const IS_IP_WHITELISTED = {
  id: "isIPWhitelisted",
  title: "IP Whitelisted",
  render: (scope) => (scope.isIPWhitelisted ? GREEN_TICK : RED_CROSS),
};

export const RestApiColumns = [
  ENDPOINT,
  AUTH_TYPE,
  SCOPES,
  IS_IP_WHITELISTED,
];

export const WebsocketApiColumns = [ROUTE, AUTH_TYPE, SCOPES];
