import React from 'react'
import { Segment } from 'semantic-ui-react'

function Footer () {
    return (
            <Segment inverted vertical style={{ padding: '2.5em 0', marginTop: '80px', backgroundColor: '#34353a', color: '#fff', textAlign: 'center'}}>
                <p> Copyright © 2023 Ikano Bank. Alla rättigheter förbehålls. 
                    <br></br>                   
                    Ikano Bank AB (publ) - Organisationsnr: 516406-0922. Styrelsens säte: Älmhult. Huvudkontor: Hyllie Boulevard 27, 215 32 Malmö, Sverige.
                </p>                                
            </Segment>
    )
};

export default Footer