import React from "react";
import Sidebar from "components/Sidebar/Sidebar";
import { NavLink } from "react-router-dom";
import SidebarHeader from "components/Sidebar/SidebarHeader";
import { isAuthenticated, isDeveloperToolsUser } from "services/self";
import Editor from "components/Sidebar/Editor";
import { useLocation } from "react-router-dom";

function SideNav() {
  const { pathname } = useLocation();

  return (
    <Sidebar>
      <SidebarHeader>Developer Tools</SidebarHeader>
      {isAuthenticated() && (
        <NavLink
          to="/developer-tools/websocket-test-client"
          className="item link"
        >
          WebSocket Test Client
        </NavLink>
      )}
      {isDeveloperToolsUser() && (
        <NavLink
          to="/developer-tools/api-gw-self-service"
          className="item link"
        >
          API GW Self-Service
        </NavLink>
      )}
      {isDeveloperToolsUser() && (
        <>
          <NavLink
            to="/developer-tools/api-specification-editor"
            className="item link"
          >
            API Specification Editor
          </NavLink>
          {pathname.includes("api-specification-editor") && <Editor />}
        </>
      )}
    </Sidebar>
  );
}

export default SideNav;
