// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import React, { useState, useEffect, useRef } from "react";

// swagger-ui
import SwaggerUI from "swagger-ui";
import "swagger-ui/dist/swagger-ui.css";

// services
import { isRegistered } from "services/self";
import { updateUsagePlansAndApisList, getApi } from "services/api-catalog";

import PropTypes from "prop-types";
// components
import SwaggerLayoutPlugin from "components/SwaggerUiLayout";
import PageWithSidebar from "components/PageWithSidebar";
import Sidebar from "components/Sidebar/Sidebar";
import SidebarHeader from "components/Sidebar/SidebarHeader";
import { RestAPIsCollapse } from "components/Sidebar/Collapse";
import { Link } from "react-router-dom";
import _ from "lodash";
// state
import { store } from "services/state.js";

// semantic-ui
import {
  Grid,
  GridColumn,
  Menu,
  Label,
  Header,
  Icon,
  Container,
  Segment,
  Search,
} from "semantic-ui-react";
import "./websocket.css";

const RestApis = (props) => {
  const containerRef = useRef();
  const swaggerUiInstanceRef = useRef();
  const hasRootRef = useRef(false);
  const [restApisList, setRestApisList] = useState(store.apiList.restApis);
  const loaded = store.apiList.loaded;
  const apiId = props.match.params.apiId;
  const initialState = { isLoading: false, results: [], value: "" };
  const [searchState, setSearchState] = useState(initialState);

  useEffect(() => {
    async function fetchData() {
      await updateUsagePlansAndApisList(true);
      setRestApisList(store.apiList.restApis);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const updateApi = async (isInitial) => {
      try {
        const api = getApi(apiId || "ANY", true, isInitial);
        if (containerRef.current == null) return;
        if (containerRef.current && api) {
          const elem = containerRef.current;
          hasRootRef.current = elem != null;
          if (api && elem != null) {
            const cell = {
              shouldPreauthorizeApiKey: false,
              preauthorizeApiKey: () => {
                cell.shouldPreauthorizeApiKey = true;
              },
            };
            const swaggerUiConfig = {
              domNode: containerRef.current,
              plugins: [SwaggerLayoutPlugin],
              supportedSubmitMethods: [],
              spec: api.content,
              onComplete: () => cell.preauthorizeApiKey(),
            };
            if (
              isRegistered() &&
              process.env.REACT_APP_ENVIRONMENT_NAME !== "prod"
            ) {
              delete swaggerUiConfig.supportedSubmitMethods;
            }
            const uiHandler = SwaggerUI(swaggerUiConfig);
            swaggerUiInstanceRef.current = uiHandler;
            cell.preauthorizeApiKey = () => {
              if (store.apiKey) {
                uiHandler.preauthorizeApiKey("api_key", store.apiKey);
              }
            };
            if (cell.shouldPreauthorizeApiKey) {
              cell.preauthorizeApiKey();
            }
          }
        }
      } catch (error) {
        console.error("Error updating API:", error);
      }
    };
    if (!props || !props.match || !props.match.params) {
      return;
    }
    if (apiId || props.match.params.stage) {
      updateApi(true);
    }
  }, [apiId, props, props.match.params.stage, props.match.url, restApisList]);

  let result = props.match.url;
  const findTitle = (data, apiId, path) => {
    data.forEach((data) => {
      let temp = path === "" ? data.title : path.concat(data.title);
      if (data.apiId && apiId === data.apiId) {
        result = temp;
      } else {
        if (Array.isArray(data.content)) {
          findTitle(data.content, apiId, temp.concat("/"));
        }
      }
    });
  };
  findTitle(restApisList, apiId, "");

  const resultRenderer = ({ title }) => <Label content={title} />;

  resultRenderer.propTypes = {
    title: PropTypes.string,
    content: PropTypes.object,
  };

  const source = restApisList.reduce((memo, data) => {
    const key = _.uniqueId();
    // eslint-disable-next-line no-param-reassign
    memo[key] = {
      title: data.title,
      results: data.content,
    };
    return memo;
  }, {});

  const handleResultSelect = (e, { result }) => {
    setSearchState({ value: result.title, apiId: result.apiId });
  };

  const handleSearchChange = (e, { value }) => {
    setSearchState({ isLoading: true, value });
    setTimeout(() => {
      if (value.length < 1) return setSearchState(initialState);

      const re = new RegExp(_.escapeRegExp(value), "i");
      const isMatch = (result) => {
        return re.test(result.title);
      };

      const filteredResults = _.reduce(
        source,
        (memo, data, key) => {
          const results = _.filter(data.results, isMatch);
          if (results.length) memo[key] = { name: data.title, results }; // eslint-disable-line no-param-reassign
          return memo;
        },
        {}
      );
      setSearchState({
        isLoading: false,
        results: filteredResults,
      });
    }, 300);
  };

  const sidebar = (
    <Sidebar>
      <SidebarHeader
        className="item"
        style={{ fontWeight: "400", cursor: "pointer" }}
        href={"/apis/"}
      >
        Rest APIs
      </SidebarHeader>
      <Grid>
        <GridColumn width={8}>
          <Search
            category
            placeholder="Search API"
            loading={searchState.isLoading}
            onResultSelect={handleResultSelect}
            onSearchChange={_.debounce(handleSearchChange, 500, {
              leading: true,
            })}
            resultRenderer={(result) => (
              <Menu.Menu
                as={Link}
                to={`/apis/${result.apiId}`}
                style={{
                  display: "inline-block",
                  width: "100%",
                  height: "100%",
                }}
              >
                {result.title}
              </Menu.Menu>
            )}
            results={searchState.results}
            value={searchState.value}
          />
        </GridColumn>
      </Grid>
      {loaded && (
        <RestAPIsCollapse data={restApisList} path={result} index={0} />
      )}
    </Sidebar>
  );

  let errorHeader;
  let errorBody;

  if (loaded) {
    if (!restApisList.length) {
      errorHeader = "No APIs Published";
      errorBody =
        "Your administrator hasn't added any APIs to your account. Please contact them to publish an API.";
    } /*else if (!api) {
        errorHeader = 'No Such API'
        errorBody = 'The selected API doesn\'t exist.'
      }*/
  }
  return (
    <PageWithSidebar
      sidebarContent={sidebar}
      SidebarPusherProps={{ className: "swagger-section" }}
    >
      <div
        className="swagger-ui-wrap"
        ref={containerRef}
        style={{ padding: "0 20px" }}
      >
        {errorHeader && errorBody && (
          <>
            <Header
              as="h2"
              icon
              textAlign="center"
              style={{ padding: "40px 0px" }}
            >
              <Icon name="warning sign" circular />
              <Header.Content>{errorHeader}</Header.Content>
            </Header>
            <Container text textAlign="justified">
              <p>{errorBody}</p>
            </Container>
          </>
        )}
        {!apiId && (
          <Container fluid style={{ padding: "2em" }}>
            <Header as="h1">Rest APIs</Header>
            <Segment>
              <p>
                {" "}
                visualize RESTful APIs and ensuring a consistent approach to API
                development and integration.{" "}
              </p>
            </Segment>
          </Container>
        )}
      </div>
    </PageWithSidebar>
  );
};
export default RestApis;
