import React from 'react'
import Sidebar from 'components/Sidebar/Sidebar'
import Collapse from './Sidebar/Collapse'
import { REST_API, WEBSOCKET_API } from 'pages/EndpointSecurity'
import {
    isRestAPIUser,
    isWsAPIUser
  } from "services/self"

function EndpointSecurityMenu({apiList,showTable,api,selected,searchResult}) {
    
    return (
        <Sidebar>            
            {isRestAPIUser() && <Collapse title={REST_API} apiList={apiList.restApis} showTable={showTable} dropdown={false} api={api} selected={selected} searchResult = {searchResult} />}
            {isWsAPIUser() && <Collapse title={WEBSOCKET_API} apiList={apiList.websocketApis} showTable={showTable} dropdown={false} api={api} selected={selected} searchResult = {searchResult} />}
        </Sidebar>
    )
}

export default EndpointSecurityMenu
