import { apiGatewayClientWithCredentials as getApiGatewayClient } from "services/api";

const getAccountsWithFilter = async (filter) => {
  const apiGatewayClient = await getApiGatewayClient();
  const response = await apiGatewayClient.get(
    "/admin/accounts",
    undefined,
    undefined,
    { queryParams: { filter } }
  );
  return response.data.accounts;
};

const url = ([first, ...rest], ...parts) =>
  first + parts.map((p, i) => encodeURIComponent(p) + rest[i]).join("");

const fetch = (method, target, params = {}) =>
  getApiGatewayClient()
    .then((apiGatewayClient) =>
      apiGatewayClient[method](target, {}, params, {})
    )
    .then((response) => response)
    .catch((error) => {
      throw error && (error.data || error);
    });

export const fetchRegisteredAccounts = () =>
  getAccountsWithFilter("registered");
export const fetchAdminAccounts = () => getAccountsWithFilter("admin");
export const fetchOnlyRestAccounts = () =>
  getAccountsWithFilter("rest-apis-users");
export const fetchOnlyWsAccounts = () =>
  getAccountsWithFilter("websocket-apis-users");
export const fetchOnlyEventBasedAccounts = () =>
  getAccountsWithFilter("event-based-apis-users");
export const fetchOnlyPublicAccounts = () =>
  getAccountsWithFilter("public-apis-users");
export const fetchOnlySpecificRestAccounts = () =>
  getAccountsWithFilter("specific-rest-apis-users");
export const fetchOnlySpecificWebsocketAccounts = () =>
  getAccountsWithFilter("specific-websocket-apis-users");
export const fetchDeveloperToolsAccounts = () =>
  getAccountsWithFilter("developer-tools-users");
export const fetchPendingRequestAccounts = () =>
  getAccountsWithFilter("pendingRequest");
export const fetchPendingInviteAccounts = () =>
  getAccountsWithFilter("pendingInvite");

export const deleteAccountsByUserId = async (accounts) => {
  await accounts.forEach(async (account) => {
    const response = await fetch(
      "delete",
      url`/admin/accounts/${account.UserId}`
    );

    if (response.status === 200) {
      await fetch("post", url`/admin/notify/deletion`, {
        type: "UserDeletion",
        email: account.EmailAddress,
      });
    }
  });
};
// TODO: verify if this is even correct - I suspect it should've posted to
// `/admin/accounts/:userId/denyRequest` instead.
export const deleteInviteByUserId = (userId) =>
  fetch("delete", url`/admin/accounts/${userId}`);
export const createInviteByEmail = (email) =>
  fetch("post", "/admin/accounts", { targetEmailAddress: email });
export const resendInviteByEmail = (email) =>
  fetch("put", "/admin/accounts/resendInvite", { targetEmailAddress: email });

export const promoteAccountByUserId = async (accounts) => {
  await accounts.forEach(async (account) => {
    const response = await fetch(
      "put",
      url`/admin/accounts/${account.UserId}/promoteToAdmin`
    );

    if (response.status === 200) {
      await fetch("post", url`/admin/notify/adminPromotion`, {
        type: "AdminPromotion",
        email: account.EmailAddress,
      });
    }
  });
};
export const approveAccountRequestByUserId = (userId) =>
  fetch("put", url`/admin/accounts/${userId}/approveRequest`);
export const denyAccountRequestByUserId = (userId) =>
  fetch("put", url`/admin/accounts/${userId}/denyRequest`);

//user groups accounts
export const promoteAccountByUserIdToUserGroups = async (
  accounts,
  userGroups
) => {
  const actions = {
    "Rest API group": "promoteToRestAccount",
    "Websocket API group": "promoteToWsAccount",
    "Event Based API group": "promoteToEventBasedAccount",
    "Public API group": "promoteToPublicAccount",
    "Specific Rest API group": "promoteToSpecificRestAccount",
    "Specific Websocket API group": "promoteToSpecificWsAccount",
    "Developer Tools Group": "promoteToDeveloperToolsAccount",
  };

  // get the key value of group names
  const getKeyForValue = (object, targetValue) => {
    for (const key in object) {
      if (object[key] === targetValue) {
        return key;
      }
    }
    return null; // Return null if the value is not found
  };

  for (const userGroup of userGroups) {
    accounts.forEach(async (account) => {
      const actionValue = actions[userGroup];

      if (actionValue) {
        const response = await fetch(
          "put",
          url`/admin/accounts/${account.UserId}/${actionValue}`
        );
        if (response.status === 200) {
          const groupName = getKeyForValue(actions, actionValue);
          await fetch("post", url`/admin/notify/userPromotion`, {
            type: "UserPromotion",
            email: account.EmailAddress,
            promotedResource: groupName || userGroup,
          });
        }
      }
    });
  }
};

export const deleteAccountsFromUserGroup = async (accounts, groupName) => {
  const userGroups = {
    RestAPIOnlyUserName: "REST APIs",
    WsAPIOnlyUserName: "WebSocket APIs",
    EventBasedUsersGroupName: "Event Based APIs",
    PublicAPIUsersGroupName: "Public APIs",
    SpecificRestAPIUsersGroupName: "Specific REST APIs",
    SpecificWsAPIUsersGroupName: "Specific WebSocket APIs",
    DeveloperToolsUserGroupName: "Developer Tools",
  };

  await accounts.forEach(async (account) => {
    const response = await fetch(
      "delete",
      url`/admin/accounts/${account.UserId}/${groupName}/removeUserFromUserGroup`
    );

    if (response.status === 200) {
      await fetch("post", url`/admin/notify/revokedAccess`, {
        type: "AccessRevoked",
        email: account.EmailAddress,
        revokedResource: userGroups[groupName],
      });
    }
  });
};
