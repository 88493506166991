import React, { useCallback, useEffect, useState } from "react";
import { Container, Header } from "semantic-ui-react";

import * as MessageList from "components/MessageList";
import * as AccountService from "services/accounts";
import * as AccountsTable from "components/Admin/Accounts/AccountsTable";
import * as AccountsTableColumns from "components/Admin/Accounts/AccountsTableColumns";
import * as Promote from "components/Admin/Accounts/Promote";
import * as Delete from "components/Admin/Accounts/Delete";
import { TableActions } from "components/Admin/Accounts/TableActions";
import { store } from "services/state";

const RegisteredAccounts = () => {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [promoteToAdminModalOpen, setPromoteToAdminModalOpen] = useState(false);
  const [promoteToUserGroupsModalOpen, setPromoteToUserGroupsModalOpen] =
    useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const [messages, sendMessage] = MessageList.useMessages();

  const refreshAccounts = async () => {
    const [all, admins] = await Promise.all([
      AccountService.fetchRegisteredAccounts(),
      AccountService.fetchAdminAccounts(),
    ]);

    const allMap = {};

    all.forEach((user) => {
      allMap[user.UserId] = user;
    });
    admins.forEach((admin) => {
      admin.IsAdmin = true;
      if (admin.EmailAddress === store.user.email) {
        admin.EmailAddress += " (you)";
      }
      allMap[admin.UserId] = admin;
    });

    return setAccounts(Object.values(allMap));
  };

  // Initial load
  useEffect(() => {
    refreshAccounts().finally(() => setLoading(false));
  }, []);

  const onConfirmDelete = useCallback(async () => {
    setLoading(true);
    setDeleteModalOpen(false);
    try {
      await AccountService.deleteAccountsByUserId(selectedAccounts);
      sendMessage((dismiss) => (
        <Delete.DeleteSuccessMessage
          accounts={selectedAccounts}
          userGroup={"API portal registered users"}
          dismiss={dismiss}
          isAllAccounts={accounts.length === selectedAccounts.length}
        />
      ));
      await refreshAccounts();
    } catch (error) {
      sendMessage((dismiss) => (
        <Delete.DeleteFailureMessage
          accounts={selectedAccounts}
          userGroup={"API portal registered users"}
          dismiss={dismiss}
          errorMessage={error.message}
          isAllAccounts={accounts.length === selectedAccounts.length}
        />
      ));
    } finally {
      setLoading(false);
    }
  }, [sendMessage, accounts, selectedAccounts]);

  const onConfirmPromoteToAdmin = useCallback(async () => {
    setLoading(true);
    setPromoteToAdminModalOpen(false);
    try {
      await AccountService.promoteAccountByUserId(selectedAccounts);
      sendMessage((dismiss) => (
        <Promote.PromoteSuccessMessage
          accounts={selectedAccounts}
          userGroups={["Admin"]}
          dismiss={dismiss}
        />
      ));

      await refreshAccounts();
    } catch (error) {
      sendMessage((dismiss) => (
        <Promote.PromoteFailureMessage
          accounts={selectedAccounts}
          userGroups={userGroups}
          dismiss={dismiss}
          errorMessage={error.message}
        />
      ));
    } finally {
      setLoading(false);
      setSelectedAccounts([]);
    }
  }, [sendMessage, userGroups, selectedAccounts]);

  const onConfirmPromoteToUserGroups = useCallback(
    async (userGroups) => {
      setLoading(true);
      setPromoteToUserGroupsModalOpen(false);

      try {
        await AccountService.promoteAccountByUserIdToUserGroups(
          selectedAccounts,
          userGroups
        );
        sendMessage((dismiss) => (
          <Promote.PromoteSuccessMessage
            accounts={selectedAccounts}
            userGroups={userGroups}
            dismiss={dismiss}
          />
        ));

        await refreshAccounts();
      } catch (error) {
        sendMessage((dismiss) => (
          <Promote.PromoteFailureMessage
            accounts={selectedAccounts}
            userGroups={userGroups}
            dismiss={dismiss}
            errorMessage={error.message}
          />
        ));
      } finally {
        setLoading(false);
        setSelectedAccounts([]);
        setUserGroups([]);
      }
    },
    [sendMessage, selectedAccounts]
  );

  return (
    <Container fluid style={{ padding: "2em" }}>
      <Header as="h1">Registered accounts</Header>
      <MessageList.MessageList messages={messages} />
      <AccountsTable.AccountsTable
        accounts={accounts}
        columns={[
          AccountsTableColumns.EmailAddress,
          AccountsTableColumns.IsAdmin,
          AccountsTableColumns.DateRegistered,
          AccountsTableColumns.RegistrationMethod,
          AccountsTableColumns.ApiKeyId,
        ]}
        loading={loading}
        selectedAccounts={selectedAccounts}
        setSelectedAccounts={setSelectedAccounts}
      >
        <TableActions
          isSelected={!loading && selectedAccounts.length > 0}
          canDelete={!loading && selectedAccounts.length > 0}
          onClickDelete={() => setDeleteModalOpen(true)}
          canPromote={true}
          IsRestAPIUser={!loading && selectedAccounts.length > 0}
          IsWsAPIUser={!loading && selectedAccounts.length > 0}
          IsEventBasedAPIUser={!loading && selectedAccounts.length > 0}
          IsPublictAPIUser={!loading && selectedAccounts.length > 0}
          IsSpecRestAPIUser={!loading && selectedAccounts.length > 0}
          IsSpecWsAPIUser={!loading && selectedAccounts.length > 0}
          IsDeveloperToolsUser={!loading && selectedAccounts.length > 0}
          promoteToUserGroups={() => setPromoteToUserGroupsModalOpen(true)}
          promoteToAdmin={() => setPromoteToAdminModalOpen(true)}
          refreshAccounts={() => {
            refreshAccounts();
            setSelectedAccounts([]);
          }}
          userGroups={userGroups}
          setUserGroups={setUserGroups}
        />
      </AccountsTable.AccountsTable>
      <Delete.DeleteAccountModal
        accounts={selectedAccounts}
        userGroup={"API portal registered users"}
        onConfirm={onConfirmDelete}
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setSelectedAccounts([]);
        }}
        isAllAccounts={accounts.length === selectedAccounts.length}
      />
      <Promote.PromoteAccountToUserGroupsModal
        accounts={selectedAccounts}
        onConfirm={onConfirmPromoteToUserGroups}
        open={promoteToUserGroupsModalOpen}
        onClose={() => {
          setPromoteToUserGroupsModalOpen(false);
          setSelectedAccounts([]);
          setUserGroups([]);
        }}
        userGroups={userGroups}
      />
      <Promote.PromoteAccountToAdminModal
        accounts={selectedAccounts}
        onConfirm={onConfirmPromoteToAdmin}
        open={promoteToAdminModalOpen}
        onClose={() => {
          setPromoteToAdminModalOpen(false);
          setSelectedAccounts([]);
          setUserGroups([]);
        }}
      />
    </Container>
  );
};

export default RegisteredAccounts;
