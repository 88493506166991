import React, { useEffect, useMemo, useState } from 'react'
import PageWithSidebar from 'components/PageWithSidebar'
import { Header, Icon, Container, Segment, Loader, List } from 'semantic-ui-react'
import { store } from 'services/state'
import { updateEventBasedApisList } from 'services/api-catalog'
import AsyncApiComponent from '@asyncapi/react-component/browser/index'
import "@asyncapi/react-component/styles/default.min.css";
import AsyncApisMenu from 'components/AsyncApisMenu'
import Parser from '@asyncapi/parser/browser/index'
import { withRouter } from 'react-router'

function EventBasedApis(props) {
  const [asyncApisList, setAsyncApisList] = useState(store.eventBasedApiList.eventBasedApis)
  const [content, setContent] = useState('Loading')
  const [diagnostics, setDiagnostics] = useState([])
  const loaded = store.eventBasedApiList.loaded
  const directory = props.match.params.directory
  const [apiDirectory, setApiDirectory] = useState(directory ? directory.split('/') : [])

  useEffect(() => {    
    async function fetchData() {
      await updateEventBasedApisList(true);     
      setAsyncApisList(store.eventBasedApiList.eventBasedApis)
    }
    fetchData();
  }, []);  

  useMemo(()=>{
    setApiDirectory(directory ? directory.split('/') : [])
  },[directory])

  useMemo(()=>{
    let apiContent = 'Loading'
    const displayContent = (apiList, index) => {
      // eslint-disable-next-line array-callback-return
      apiList.map(api => {
      if(apiDirectory[index] === api.title){
        api.content ? displayContent(api.content, index+1) : apiContent = api
      }
     })
     if(apiContent === 'Loading') apiContent = 'No APIs found'
    }
    if(loaded) {
      displayContent(asyncApisList, 0)
      if(apiContent.asyncapi){
        parseAPI(apiContent)
      } else {
        setContent(apiContent)
        setDiagnostics([])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[apiDirectory, asyncApisList])    

    async function parseAPI(api) {
      const parser = new Parser()
      const { document, diagnostics } = await parser.parse(api)
      if(document) {
        setContent(document._json)
        setDiagnostics([])
      }
      let errors = []
      // eslint-disable-next-line array-callback-return
      diagnostics.map(error => {
        if(error.severity === 0) {
          setContent("Please fix the errors in the AsyncAPI document or upgrade the document to the latest version")
          errors.push(error.message)
        } 
      })
      setDiagnostics(errors)
    }

    return (
        <PageWithSidebar
            sidebarContent={loaded ? <AsyncApisMenu apiDirectory={apiDirectory} data={asyncApisList} sidebarTitle={'Event Based APIs'} url={'event-based-apis'} /> : <Loader active/>}
            SidebarPusherProps={{ className: 'swagger-section' }}
        >
            {directory ? 
            (content.asyncapi ? 
                <AsyncApiComponent schema={content}/> : 
                <Container style={{ padding: '40px 0px', width: '600px' }}>
                    {content === 'Loading' ? <Loader active /> :
                    <>
                    <Header icon textAlign='center' style={{ display: 'auto' }}>
                      <Icon size='huge' name='red warning sign' />
                      {content}
                    </Header>
                    <List bulleted textAlign='left'>
                      {diagnostics.map((error, index)=><List.Item key={index}>{error}</List.Item>)}
                    </List>
                    </>
                    }
                </Container>
            ) :
                <Container fluid style={{ padding: '2em' }}>
                    <Header as='h1'>Event based APIs</Header>
                    <Segment>
                        <p>Visualization of the event driven APIs that outline the asynchronous API endpoints and their usage, 
                        allowing developers to understand how to handle asynchronous tasks and responses in their applications. The users 
                        can also see the Kafka topics used in the APIs.</p>
                    </Segment>
                </Container>
            }
        </PageWithSidebar>
    )
}

export default withRouter(EventBasedApis);
