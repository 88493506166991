import React, { useState } from 'react'
import Sidebar from './Sidebar/Sidebar'
import SidebarHeader from './Sidebar/SidebarHeader'
import { Loader, Menu, Search } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { AsyncApiCollapse } from './Sidebar/Collapse'

function AsyncApisMenu({apiDirectory, data, sidebarTitle, url}) {
    const [searchQuery, setSearchQuery] = useState('')
    const [result, setResult] = useState(null)

    let searchResult = []
    let index = 0
    const search = (apiList, value, title) => {
        apiList.forEach(api => {
        let path = title
        if (api.title.toLowerCase().includes(value.toLowerCase())) {
            path = (title==='') ? api.title : title.concat('/').concat(api.title)
            addResult(path, api.content, api.error)
        }
        else if (api.content.length && api.content[0].title) search(api.content, value, (title==='') ? api.title : title.concat('/').concat(api.title))
        });
    }

    const addResult = (path, apiList, error) => {
        if(apiList.length > 0 && (apiList[0].asyncapi || error)) {
        index++
        const result = {
            key: index,
            title: path.replaceAll('/', '>'),
            url: `/${url}/`.concat(path)
        }
        searchResult.push(result)
        }
        else {
        apiList.forEach(api => {
            if(typeof api !== 'string') addResult(path.concat('/').concat(api.title), api.content, api.error)
        })
        }
    }

    const handleSearchChange = (e, {value}) => {
        setSearchQuery(value)
        value.length>2 && search(data, value, '')
        setResult(searchResult)
    }
        
  return (
    <Sidebar>
            <SidebarHeader 
              className='item' style={{ fontWeight: '400', cursor: 'pointer' }}
              as={Link}
              to={`/${url}`}
            >
                {sidebarTitle}
            </SidebarHeader>
            <Search 
              placeholder='Search API' 
              minCharacters={3}
              onSearchChange={handleSearchChange} 
              value={searchQuery}
              results = {result} 
              resultRenderer={(result) => (
                <Menu.Menu
                  as={Link}
                  to={result.url}
                  style={{ display: 'inline-block', width: '100%', height: '100%' }}
                >
                  {result.title}
                </Menu.Menu>
              )}
            />              
            {!data.length ? <Loader active />:
            <AsyncApiCollapse
              apiDirectory={apiDirectory}
              url={`/${url}`}
              data={data} 
              index={0}
            />}
        </Sidebar>
  )
}

export default AsyncApisMenu