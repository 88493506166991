import React from "react";
import { Image, Table } from "semantic-ui-react";

function DiagnosticTable({ diagnostics }) {
  const severity = (severity) => {
    let icon;
    switch (severity) {
      case 0:
        icon = "error";
        break;
      case 1:
        icon = "warning";
        break;
      case 2:
        icon = "hint";
        break;
      case 3:
        icon = "info";
        break;
      default:
        icon = "error";
    }
    return (
      <Image
        src={`/custom-content/${icon}-icon.svg`}
        centered
        title="Error"
        alt="Error"
        width="13"
        height="13"
      />
    );
  };
  return (
    <Table celled selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Severity</Table.HeaderCell>
          <Table.HeaderCell>Code</Table.HeaderCell>
          <Table.HeaderCell>Message</Table.HeaderCell>
          {diagnostics[0].range && (
            <Table.HeaderCell>Line Number</Table.HeaderCell>
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {diagnostics.map((error, index) => (
          <Table.Row key={index}>
            <Table.Cell>{severity(error.severity)}</Table.Cell>
            <Table.Cell>{error.code || error.keyword}</Table.Cell>
            <Table.Cell>
              {error.instancePath} {error.message}
            </Table.Cell>
            {error.range && <Table.Cell>{error.range.start.line}</Table.Cell>}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

export default DiagnosticTable;
