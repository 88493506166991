import PageWithSidebar from "components/PageWithSidebar";
import { BrowserRouter, Link, Switch } from "react-router-dom";
import { DeveloperToolsRoute, RegisteredRoute } from "index";
import SideNav from "./SideNav";
import WebsocketTestClient from "./WebsocketTestClient";
import APIGWSelfService from "./APIGWSelfService";
import Editor from "./Editor";
import { Card, Container, Image } from "semantic-ui-react";
import { isDeveloperToolsUser } from "services/self";

function DeveloperTools() {
  return (
    <BrowserRouter>
      <PageWithSidebar sidebarContent={<SideNav />}>
        <Switch>
          {process.env.REACT_APP_ENVIRONMENT_NAME !== "prod" && (
            <RegisteredRoute
              exact
              path="/developer-tools/websocket-test-client"
              component={WebsocketTestClient}
            />
          )}
          <DeveloperToolsRoute
            exact
            path="/developer-tools/api-gw-self-service"
            component={APIGWSelfService}
          />
          <DeveloperToolsRoute
            exact
            path="/developer-tools/api-specification-editor"
            component={Editor}
          />
          <DeveloperToolsRoute
            path="/developer-tools/api-specification-editor/:apiType/:name"
            component={Editor}
          />
          <Container style={{ padding: "2em", width: "100%" }}>
            <Card.Group centered>
              <Card as={Link} to="/developer-tools/websocket-test-client">
                <Card.Content>
                  <Card.Header>WebSocket Test Client</Card.Header>
                  <Card.Description textAlign="center">
                    <Image
                      width="50"
                      height="50"
                      centered
                      src="/custom-content/test.png"
                    />
                  </Card.Description>
                  <Card.Description textAlign="center">
                    Click here to test the WebSocket API endpoints
                  </Card.Description>
                </Card.Content>
              </Card>
              {isDeveloperToolsUser() && (
                <Card as={Link} to="/developer-tools/api-gw-self-service">
                  <Card.Content>
                    <Card.Header>API GW Self Serivce</Card.Header>
                    <Card.Description textAlign="center">
                      <Image
                        width="50"
                        height="50"
                        centered
                        src="/custom-content/self-service.png"
                      />
                    </Card.Description>
                    <Card.Description textAlign="center">
                      Click here to use the API GW Self Service to create, edit
                      and delete the endpoints
                    </Card.Description>
                  </Card.Content>
                </Card>
              )}
              {isDeveloperToolsUser() && (
                <Card as={Link} to="/developer-tools/api-specification-editor">
                  <Card.Content>
                    <Card.Header>API Specification Editor</Card.Header>
                    <Card.Description textAlign="center">
                      <Image
                        width="50"
                        height="50"
                        centered
                        src="/custom-content/documentation.jpg"
                      />
                    </Card.Description>
                    <Card.Description textAlign="center">
                      Click here to edit and visualize Swagger and Async API
                      definitions
                    </Card.Description>
                  </Card.Content>
                </Card>
              )}
            </Card.Group>
          </Container>
        </Switch>
      </PageWithSidebar>
    </BrowserRouter>
  );
}

export default DeveloperTools;
