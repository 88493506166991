import { apiGatewayClientWithCredentials as getApiGatewayClient } from "services/api";

const dev = process.env.REACT_APP_ENVIRONMENT_NAME === "dev";

export const fetchDropdownOptions = async (apiType) => {
  const apiGatewayClient = await getApiGatewayClient();
  const response = await apiGatewayClient.get(
    `/self-service/${apiType}/modules/dropdown-options`,
    {},
    {},
    {}
  );
  return response.data;
};

export const fetchAllModuleNames = async (apiType) => {
  const apiGatewayClient = await getApiGatewayClient();
  const response = await apiGatewayClient.get(
    `/self-service/${apiType}/modules/api-names`,
    {},
    {},
    {}
  );
  if (response.data.error) {
    return {};
  } else {
    return response.data.data;
  }
};

export const fetchEndpointDetail = async (apiType, params) => {
  const apiGatewayClient = await getApiGatewayClient();
  const response = await apiGatewayClient.get(
    `/pendingForDeployment/${apiType}/fetchUserInputFile`,
    {},
    {},
    { queryParams: params }
  );

  return response.data;
};

export const fetchEndpointDetails = async (apiType, moduleName, apiName) => {
  const apiGatewayClient = await getApiGatewayClient();

  const response = dev
    ? await apiGatewayClient.get(
        `/self-service/${apiType}/modules/endpoints`,
        {},
        {},
        {
          queryParams: {
            moduleName: moduleName,
            name: apiName,
          },
        }
      )
    : await apiGatewayClient.get(
        `/pendingForDeployment/${apiType}/endpointsDetails/${moduleName}/${apiName}`
      );

  if (response.data.error) {
    return { endPointDetails: [] };
  } else {
    return response.data.data;
  }
};

export const submitCreateConfig = async (apiType, config) => {
  const apiGatewayClient = await getApiGatewayClient();
  try {
    const response = await apiGatewayClient.post(
      `/self-service/${apiType}/store/endpoints`,
      {},
      config,
      {}
    );
    return response.data;
  } catch (e) {
    return e.data;
  }
};

export const submitPromoteConfig = async (apiType, config) => {
  const apiGatewayClient = await getApiGatewayClient();
  try {
    const response = await apiGatewayClient.post(
      `/pendingForDeployment/${apiType}/promoteEndpoint`,
      {},
      config,
      {}
    );
    return response.data;
  } catch (e) {
    return e.data;
  }
};
