import React, { useState } from "react";

import { isAdmin } from "services/self";

import { observer } from "mobx-react";

import { Menu, Icon } from "semantic-ui-react";
import Sidebar from "components/Sidebar/Sidebar";
import SidebarHeader from "components/Sidebar/SidebarHeader";
import MenuLink from "components/MenuLink";

const CollapsibleMenu = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <MenuLink onClick={toggleExpand}>
        {title}
        <Icon name={isExpanded ? "caret down" : "caret right"} />
      </MenuLink>
      {isExpanded && children}
    </>
  );
};

export const SideNav = observer(() => {
  return (
    isAdmin() && (
      <Sidebar>
        <SidebarHeader>Admin</SidebarHeader>
        <MenuLink to="/admin/apis">APIs</MenuLink>
        <CollapsibleMenu title="Accounts">
          <Menu.Menu>
            <MenuLink to="/admin/accounts">Users</MenuLink>
            <MenuLink to="/admin/accounts/admins">Admins</MenuLink>
            <CollapsibleMenu title="Users Group">
              <MenuLink to="/admin/accounts/rest-apis-users">
                Rest API Users
              </MenuLink>
              <MenuLink to="/admin/accounts/websocket-apis-users">
                Websocket API Users
              </MenuLink>
              <MenuLink to="/admin/accounts/event-based-apis-users">
                Event-Based API Users
              </MenuLink>
              <MenuLink to="/admin/accounts/public-apis-users">
                Public API Users
              </MenuLink>
              <MenuLink to="/admin/accounts/specific-rest-apis-users">
                Specific Rest API Users
              </MenuLink>
              <MenuLink to="/admin/accounts/specific-websocket-apis-users">
                Specific Websocket API Users
              </MenuLink>
              <MenuLink to="/admin/accounts/developer-tools-users">
                Developer Tools Users
              </MenuLink>
            </CollapsibleMenu>
            {/* <MenuLink to='/admin/accounts/invites'>Invites</MenuLink> */}
            {/* <MenuLink to='/admin/accounts/requests'>Requests</MenuLink> */}
          </Menu.Menu>
        </CollapsibleMenu>
      </Sidebar>
    )
  );
});
