import React from 'react'
import { Button, Message, Modal } from 'semantic-ui-react'

export const PromoteAccountToUserGroupsModal = React.memo(
    ({ accounts, onConfirm, open, onClose, userGroups }) =>
      accounts && (
        <Modal size='small' open={open} onClose={onClose}>
          <Modal.Header>Confirm promotion</Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to promote the account(s){' '}
              {accounts.map((account,index)=><strong>{ (index ? ', ' : '') + account.EmailAddress }</strong>)} to the userGroups {userGroups.map((userGroup,index)=><strong>{ (index ? ', ' : '') + userGroup }</strong>)}
            </p>
            <p>
              You can revoke the access by deleting the account(s) from the user group accounts.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={onClose}>Cancel</Button>
            <Button negative onClick={()=> onConfirm(userGroups)}>
              Promote
            </Button>
          </Modal.Actions>
        </Modal>
      )
)
  
export const PromoteAccountToAdminModal = React.memo(
    ({ accounts, onConfirm, open, onClose }) =>
      accounts && (
        <Modal size='small' open={open} onClose={onClose}>
          <Modal.Header>Confirm promotion</Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to promote the account(s){' '}
              {accounts.map((account,index)=><strong>{ (index ? ', ' : '') + account.EmailAddress }</strong>)} to Admin? This will allow
              the account to perform any Admin actions, including deleting and
              promoting other accounts.
            </p>
            <p>
              Only the owner of the Developer Portal can demote the account,
              through the Cognito console.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={onClose}>Cancel</Button>
            <Button negative onClick={onConfirm}>
              Promote
            </Button>
          </Modal.Actions>
        </Modal>
      )
)
  
export const PromoteSuccessMessage = React.memo(({ accounts, userGroups, dismiss }) => (
    <Message onDismiss={dismiss} positive>
      <Message.Content>
        Successfully promoted the account(s) {accounts.map((account,index)=><strong>{ (index ? ', ' : '') + account.EmailAddress }</strong>)} to {userGroups.map((userGroup,index)=><strong>{ (index ? ', ' : '') + userGroup }</strong>)}.
      </Message.Content>
    </Message>
))
  
export const PromoteFailureMessage = React.memo(
    ({ accounts, userGroups, errorMessage, dismiss }) => (
      <Message onDismiss={dismiss} negative>
        <Message.Content>
          <p>
            Failed to promote the account(s) {accounts.map((account,index)=><strong>{ (index ? ', ' : '') + account.EmailAddress }</strong>)} to {userGroups.map((userGroup,index)=><strong>{ (index ? ', ' : '') + userGroup }</strong>)}.
          </p>
          {errorMessage && <p>Error message: {errorMessage}</p>}
        </Message.Content>
      </Message>
    )
  )
  