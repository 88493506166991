import React, { useState, useEffect, useMemo } from 'react'
import SidebarHeader from './SidebarHeader'
import MenuLink from '../MenuLink'
import { Menu } from 'semantic-ui-react'
import Sidebar from 'components/Sidebar/Sidebar'

function EndpointACLCollapse({title,apiList,showTable,selected,searchResult}) {
const [isCollapse, setisCollapse] = useState(false)
const collapse = () => {
    setisCollapse((state) => !state)
}
useEffect(()=>{
    searchResult.length &&  setisCollapse(true)
}, [searchResult])

return (
    <>
    <SidebarHeader onClick={collapse} style={{cursor:'pointer', display:'flex', alignItems:'center'}}>
        {title}
        <span className="material-symbols-outlined" style={{transform: `rotate(${isCollapse ? 180 : 0}deg)`, transition: "all 0.25s"}}>expand_more</span>
    </SidebarHeader>

    {isCollapse && apiList.map((api) =>(
    <MenuLink key={api.id} onClick={()=>showTable(api,title)} active={api.id===selected} >
        {api.name}
    </MenuLink>
    ))}
    </>    
)
}
export default EndpointACLCollapse

export function AsyncApiCollapse({data,apiDirectory,index,url}) {   
    const [active, setActive] = useState(null) 
    useMemo(()=>{
        setActive(apiDirectory.length ? apiDirectory[index] : null)
    }, [apiDirectory, index])
    const dropdown = (data) => {
        const { title, content, error} = data
        setActive(title===active ? (content[0].asyncapi || error ? title : null) : title)
    }

    const hasContent = ({content,error}) => (content.length ? (content[0].asyncapi || error): false) ? true : false
  
    return(
      <>
        {data.map(data=>(
            <React.Fragment key={data.title}>
            <MenuLink onClick={()=>dropdown(data)} to={hasContent(data) ? url+'/'+data.title : null} active={active===data.title} style={{ fontWeight: '400', display: 'flex', alignItems: 'center' }}>
                {data.title}
                {!(hasContent(data)) && 
                    <span
                     className="material-symbols-outlined"
                     style={{transform: `rotate(${(active===data.title) ? 180 : 0}deg)`, transition: "all 0.25s"}}
                    >expand_more
                    </span>
                }
            </MenuLink>
            
            {(active===data.title) && (
                !(hasContent(data)) && 
                <Menu.Item style={{ padding: '0 1em'}}>
                    {data.content.length !== 0 &&
                        <AsyncApiCollapse
                         data={data.content} 
                         style={{paddingLeft: '7px'}}
                         apiDirectory={apiDirectory}
                         index={index+1}
                         url={url+'/'+data.title}
                        />
                    }
                </Menu.Item>
            )}
            
            </React.Fragment>
        ))}  
      </>
    )
  }

export function RestAPIsCollapse({data,path,index}) {   
    const [active, setActive] = useState(null) 
    const resultantPath =path.split('/') 

    useEffect(()=>{
        resultantPath.length && setActive(resultantPath[index])
        if (data.length === 1) setActive(data[0].title)         
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index, data, path])

    const showContent = (data) => {
        const {title} = data;
        setActive(title===active ? null : title) 
    };
    
    return(
    <Sidebar>  
            {data.map((data) => (
            <React.Fragment key ={data.title}>
            <MenuLink 
            active ={active === data.title} 
            onClick={()=>showContent(data)} 
            to={data.apiId && `/apis/${data.apiId}`} 
            style={{fontWeight: '400', paddingLeft: '10px', display: 'flex', alignItems: 'center'}} >                    
                    {data.title}                   
                {Array.isArray(data.content) &&
                <span
                    className="material-symbols-outlined"
                    style={{transform: `rotate(${(active===data.title) ? 180 : 0}deg)`, transition: "all 0.25s"}}
                >expand_more
                </span>   
                }                            
            </MenuLink>           
            {active === data.title && Array.isArray(data.content) && (
            <Menu.Item style={{ padding: '0 1em'}}>
            <RestAPIsCollapse 
                index = {index+1} 
                path = {path} 
                style={{paddingLeft: '7px'}} 
                data= {data.content}                 
                />           
            </Menu.Item>)}
            </React.Fragment>
        ))}      
    </Sidebar>
        )
}  

