import React from "react";
import { Button, Checkbox, Dropdown } from "semantic-ui-react";

export function TableActions({
  isSelected,
  canDelete,
  onClickDelete,
  canPromote,
  promoteToUserGroups,
  promoteToAdmin,
  refreshAccounts,
  isAdmin,
  IsRestAPIUser,
  IsWsAPIUser,
  IsEventBasedAPIUser,
  IsPublictAPIUser,
  IsSpecRestAPIUser,
  IsSpecWsAPIUser,
  IsDeveloperToolsUser,
  userGroups,
  setUserGroups,
}) {
  const options = [
    {
      key: "restAPIUser",
      text: "Rest API group",
      value: "Rest API group",
      disabled: !IsRestAPIUser,
    },
    {
      key: "wsAPIUser",
      text: "Websocket API group",
      value: "Websocket API group",
      disabled: !IsWsAPIUser,
    },
    {
      key: "eventBasedAPIUser",
      text: "Event Based API group",
      value: "Event Based API group",
      disabled: !IsEventBasedAPIUser,
    },
    {
      key: "publicAPIUser",
      text: "Public API group",
      value: "Public API group",
      disabled: !IsPublictAPIUser,
    },
    {
      key: "specRestAPIUser",
      text: "Specific Rest API group",
      value: "Specific Rest API group",
      disabled: !IsSpecRestAPIUser,
    },
    {
      key: "specWstAPIUser",
      text: "Specific Websocket API group",
      value: "Specific Websocket API group",
      disabled: !IsSpecWsAPIUser,
    },
    {
      key: "developerToolsUser",
      text: "Developer Tools Group",
      value: "Developer Tools Group",
      disabled: !IsDeveloperToolsUser,
    },
  ];

  const handleSelectUserGroup = (e, { label, checked }) => {
    if (checked) {
      setUserGroups([...userGroups, label]);
    } else {
      setUserGroups(userGroups.filter((userGroup) => userGroup !== label));
    }
  };

  return (
    <Button.Group>
      <Button content="Delete" disabled={!canDelete} onClick={onClickDelete} />
      {canPromote && (
        <Button
          content="Promote to Admin"
          disabled={!isSelected || isAdmin}
          onClick={promoteToAdmin}
        />
      )}
      {canPromote && (
        <Dropdown
          simple
          button
          disabled={!isSelected}
          text="Select user groups"
        >
          <Dropdown.Menu>
            {options.map((option) => (
              <Dropdown.Item key={option.key}>
                <Checkbox
                  label={option.text}
                  checked={userGroups.includes(option.text)}
                  onChange={handleSelectUserGroup}
                />
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
      {canPromote && (
        <Button
          style={{ height: "38px" }}
          content="Promote"
          disabled={!isSelected || isAdmin || userGroups.length === 0}
          onClick={promoteToUserGroups}
        />
      )}
      <Button icon="refresh" role="refresh" onClick={refreshAccounts} />
    </Button.Group>
  );
}

export const handleSelectAccount = (
  account,
  selectedAccounts,
  setSelectedAccounts
) => {
  if (selectedAccounts.includes(account)) {
    setSelectedAccounts(
      selectedAccounts.filter(
        (selectedAccount) => selectedAccount.UserId !== account.UserId
      )
    ); //Deselect the account if already selected
  } else {
    setSelectedAccounts((selectedAccounts) => [...selectedAccounts, account]); //Select the account by adding it to the selectedAccounts array
  }
};
