import PageWithSidebar from "components/PageWithSidebar";
import AsyncApiComponent from "@asyncapi/react-component/browser/index";
import "@asyncapi/react-component/styles/default.min.css";
import { updateWebsocketAsyncApisList } from "services/api-catalog";
import React, { useState, useEffect, useMemo } from "react";
import { store } from "services/state";
import {
  Header,
  Icon,
  Container,
  Segment,
  Loader,
  List,
  Button,
} from "semantic-ui-react";
import AsyncApisMenu from "components/AsyncApisMenu";
import Parser from "@asyncapi/parser/browser/index";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router";

function WebSocketAPIs(props) {
  const [asyncApisList, setAsyncApisList] = useState(
    store.websocketAsyncApiList.websocketApis
  );
  const [content, setContent] = useState("Loading");
  const [diagnostics, setDiagnostics] = useState([]);
  const loaded = store.websocketAsyncApiList.loaded;
  const directory = props.match.params.directory;
  const [apiDirectory, setApiDirectory] = useState(
    directory ? directory.split("/") : []
  );

  useEffect(() => {
    async function fetchData() {
      await updateWebsocketAsyncApisList(true);
      setAsyncApisList(store.websocketAsyncApiList.websocketApis);
    }
    fetchData();
  }, []);

  useMemo(() => {
    setApiDirectory(directory ? directory.split("/") : []);
  }, [directory]);

  useMemo(() => {
    let apiContent = "Loading";
    const displayContent = (apiList, index) => {
      // eslint-disable-next-line array-callback-return
      apiList.map((api) => {
        if (apiDirectory[index] === api.title) {
          api.content
            ? displayContent(api.content, index + 1)
            : (apiContent = api);
        }
      });
      if (apiContent === "Loading") apiContent = "No APIs found";
    };
    if (loaded) {
      displayContent(asyncApisList, 0);
      if (apiContent.asyncapi) {
        parseAPI(apiContent);
      } else {
        setContent(apiContent);
        setDiagnostics([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiDirectory, asyncApisList]);

  async function parseAPI(api) {
    const parser = new Parser();
    const { document, diagnostics } = await parser.parse(api);
    if (document) {
      setContent(document._json);
      setDiagnostics([]);
    }
    let errors = [];
    // eslint-disable-next-line array-callback-return
    diagnostics.map((error) => {
      if (error.severity === 0) {
        setContent(
          "Please fix the errors in the AsyncAPI document or upgrade the document to the latest version"
        );
        errors.push(error.message);
      }
    });
    setDiagnostics(errors);
  }

  const history = useHistory();
  const openTestInterface = (content) => {
    try {
      const environment = process.env.REACT_APP_ENVIRONMENT_NAME;
      const key = Object.keys(content.servers).find(
        (key) => key.toLowerCase() === environment.toLowerCase()
      ); //For STAGE environment we're not sure if it's STG or STAGE
      const url = new URL(content.servers[key].url);
      const searchParams = url.searchParams;
      searchParams.set("authtoken", "");
      history.push({
        pathname: "/developer-tools/websocket-test-client",
        state: { webSocketUrl: url.toString() },
      });
    } catch (error) {
      window.alert("Something went wrong", error.message);
    }
  };

  return (
    <PageWithSidebar
      sidebarContent={
        loaded ? (
          <AsyncApisMenu
            apiDirectory={apiDirectory}
            data={asyncApisList}
            sidebarTitle={"WebSocket APIs"}
            url={"websocket-apis"}
          />
        ) : (
          <Loader active />
        )
      }
      SidebarPusherProps={{ className: "swagger-section" }}
    >
      {directory ? (
        content.asyncapi ? (
          [
            <Button
              key="button"
              primary
              onClick={() => openTestInterface(content)}
              style={{ float: "right", marginTop: "12px" }}
            >
              Try out
            </Button>,
            <AsyncApiComponent key="documentation" schema={content} />,
          ]
        ) : (
          <Container style={{ padding: "40px 0px", width: "600px" }}>
            {content === "Loading" ? (
              <Loader active />
            ) : (
              <>
                <Header icon textAlign="center" style={{ display: "auto" }}>
                  <Icon size="huge" name="red warning sign" />
                  {content}
                </Header>
                <List bulleted textAlign="left">
                  {diagnostics.map((error, index) => (
                    <List.Item key={index}>{error}</List.Item>
                  ))}
                </List>
              </>
            )}
          </Container>
        )
      ) : (
        <Container fluid style={{ padding: "2em" }}>
          <Header as="h1">WebSocket APIs</Header>
          <Segment>
            <p>
              Visualization of the WebSocket APIs that outline the asynchronous
              API endpoints and their usage, allowing developers to understand
              how to handle asynchronous tasks and responses in their
              applications.
            </p>
          </Segment>
        </Container>
      )}
    </PageWithSidebar>
  );
}

export default withRouter(WebSocketAPIs);
